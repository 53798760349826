import { IconClock, IconMapPin } from "@tabler/icons-react";
import AppContext from "Context/appContext";
import axios from "axios";
import "containers/BookingList/BookingDetails/index.css";
import { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import toast, { Toaster } from "react-hot-toast";
import { formatTo12Hour } from "utils";
import { BeatLoader } from "react-spinners";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const BookingDetails = (props) => {
  const { bookingDetail, getStoreTime } = props;
  const [value, setValue] = useState(new Date());
  const [times, setTimes] = useState([]);
  const [filteredTime, setFilteredTime] = useState([]);
  const [width, setWidth] = useState(300);
  const {
    appointmentDetail,
    setAppointmentDetail,
    fetchTimeLoading,
    setFetchTimeLoading,
    // storeData,
    buttonClicked,
    encryptionKey,
  } = useContext(AppContext);

  const key = "AIzaSyBlqrSdiC-YtE8DGNRJXRGXyoFQiHGsER4";

  useEffect(() => {
    const date = new Date();
    const day = date.getDay();
    setAppointmentDetail({ date, start: "", end: "" });
    setFetchTimeLoading(true);
    // fetchTimes(storeData?.store_id, day);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-based index, so adding 1
    const newDay = String(date.getDate()).padStart(2, "0"); // getDate() returns the day of the month
    const formattedDate = `${year}-${month}-${newDay}`;

    fetchTimes(day, formattedDate);
    setFetchTimeLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // set width for iframe google map
    const updateWidth = () => {
      // Set the width to 200 for screens with a width less than 600px
      if (window.innerWidth <= 768) {
        setWidth(150);
      } else {
        setWidth(300); // Set the default width for larger screens
      }
    };

    // Initial width update
    updateWidth();

    // Update width on window resize
    window.addEventListener("resize", updateWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const onChangeDate = async (e) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const selectedDate = new Date(e);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate < today) {
      toast.error("Please select today's date or a future date");
      return;
    }
    setValue(e);
    const date = new Date(e);

    setAppointmentDetail({ date, start: "", end: "" });
    setTimes([]);
    setFilteredTime([]);
    const day = date.getDay();
    setFetchTimeLoading(true);
    // await fetchTimes(storeData?.store_id, day);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-based index, so adding 1
    const newDay = String(date.getDate()).padStart(2, "0"); // getDate() returns the day of the month
    const formattedDate = `${year}-${month}-${newDay}`;

    await fetchTimes(day, formattedDate);
    setFetchTimeLoading(false);
  };

  const onTimeHandler = (time) => {
    setAppointmentDetail({
      date: appointmentDetail?.date,
      start: time?.start,
      end: time?.end,
    });
  };

  // const fetchTimes = async (store_id, day) => {
  const fetchTimes = async (day, date) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_APPOINTMENT_PRO_API_URL}/times/get-times/${day}/${date}?q_=${encryptionKey}`
      );
      setTimes(data?.times || []);
      setFilteredTime(
        data?.times?.filter((t, index) => index >= 0 && index <= 4)
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="quote-colum rd-quote-border rd-quote-mt-3 rd-quote-p-4 rd-quote-rounded-md rd-quote-border-gray-300 rd-quote-shadow rd-quote-border-teal-600">
      <Toaster position="bottom-center" />
      <div className="quote-row-justify quote-direction-column rd-quote-mb-5">
        <div className="quote-colum">
          <div className="quote-text2 rd-quote-mb-2">{bookingDetail.name}</div>
          <div className="quote-row rd-quote-mb-2">
            <span>
              {" "}
              <IconMapPin className="rd-quote-w-5 rd-quote-h-5  rd-quote-text-gray-500" />
            </span>
            <div className="quote-text1 rd-quote-ml-2 rd-quote-break-words rd-quote-max-w-[600px]">
              {bookingDetail.address !== ""
                ? bookingDetail.address.concat(", ")
                : ""}
              {bookingDetail.city}
              {bookingDetail.state}
              {bookingDetail.postcode !== ""
                ? bookingDetail.country !== ""
                  ? bookingDetail.postcode.concat(", ")
                  : bookingDetail.postcode
                : ""}
              {bookingDetail.country}
            </div>
          </div>
          <div className="quote-row">
            <IconClock className="rd-quote-w-5 rd-quote-h-5 rd-quote-text-gray-500" />
            <span
              className={`${
                getStoreTime(bookingDetail.start_time, bookingDetail.end_time)
                  ? "rd-quote-text-green-600"
                  : "rd-quote-text-rose-600"
              } rd-quote-text-sm rd-quote-font-normal
                 rd-quote-font-['SF Pro Text'] rd-quote-leading-5 rd-quote-mx-2`}
            >
              {getStoreTime(bookingDetail.start_time, bookingDetail.end_time)
                ? "Open"
                : "Closed"}
            </span>
            <span className="quote-text1">-</span>
            <div className="quote-text1 rd-quote-ml-2">
              {/* Closed at {bookingDetail.end_time.substring(0, 5)}pm */}
              Closed at{" "}
              {bookingDetail?.end_time &&
                formatTo12Hour(bookingDetail.end_time)}
            </div>
          </div>
        </div>
        <div className="quote-margin-top">
          <iframe
            title="google map"
            src={`https://www.google.com/maps/embed/v1/place?key=${key}&q="+ ${encodeURIComponent(
              bookingDetail.address
            )}"`}
            width={width}
            height="140"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      {buttonClicked === "appointment" && (
        <div className="rd-quote-mb-5 quote-margin-top">
          <Calendar
            className={"rd-quote-w-[100%] rd-quote-border-none"}
            onChange={onChangeDate}
            value={value}
          />
        </div>
      )}

      {fetchTimeLoading ? (
        <>
          <div className="rd-quote-justify-center rd-quote-flex">
            <BeatLoader loading={fetchTimeLoading} size={10} />
          </div>
        </>
      ) : (
        <>
          {filteredTime?.length === 0 ? (
            <div>
              {buttonClicked === "appointment" && (
                <div className="rd-quote-justify-center rd-quote-flex quote-text1">
                  No time available.
                </div>
              )}
            </div>
          ) : (
            <div className="rd-quote-flex rd-quote-row rd-quote-items-center">
              {buttonClicked === "appointment" && (
                <div className="rd-quote-flex time-overflow rd-quote-w-full">
                  {times?.map((time) => (
                    <div
                      className={`rd-quote-py-2 rd-quote-ml-4 rd-quote-mr-2 rd-quote-px-[43px] rd-quote-border rd-quote-rounded-md rd-quote-border-gray-300 rd-quote-shadow rd-quote-cursor-pointer ${
                        time.start === appointmentDetail?.start
                          ? `rd-quote-bg-teal-600`
                          : ``
                      }`}
                      onClick={() => onTimeHandler(time)}
                    >
                      <div
                        className={`quote-text3 rd-quote-w-[63px] ${
                          time.start === appointmentDetail?.start
                            ? `rd-quote-text-white`
                            : ``
                        }`}
                      >
                        {time?.start && formatTo12Hour(time.start)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BookingDetails;
