import { IconTrash } from "@tabler/icons-react";
import AppContext from "Context/appContext";
import { DISPLAYING_PAGES } from "constants";
import { useContext } from "react";

const IssueList = () => {
  const {
    productCartDetail,
    setProducts,
    products,
    setProductCartDetail,
    currentState,
    userSettings,
    countryDetails,
  } = useContext(AppContext);

  const deleteAndUpdatePrice = (product) => {
    const updatedList = productCartDetail.services.filter((p) => {
      const key = p?.hasOwnProperty("_id") ? "_id" : "id";
      return p[key] !== product[key];
    });
    const price = product?.sale_price?.startsWith("0")
      ? product?.retail_price
      : product?.sale_price;
    let totalPrice =
      productCartDetail?.totalPrice - (product?.price ? product?.price : price);
    if (isNaN(totalPrice) || totalPrice === undefined) {
      totalPrice = 0;
    }
    if (updatedList)
      setProductCartDetail({
        ...productCartDetail,
        totalPrice,
        services: updatedList,
      });
    const findIndex = products.findIndex((p) => {
      const key = p?.hasOwnProperty("_id") ? "_id" : "id";
      return p[key] === product[key];
    });
    if (findIndex !== -1) {
      products[findIndex].active = !product.active;
      setProducts(products);
    }
  };

  return productCartDetail?.services.map((s, idx) => (
    <div
      key={s._id || idx}
      className="rd-quote-flex rd-quote-border-b rd-quote-py-2 rd-quote-flex-row rd-quote-justify-between rd-quote-mt-2"
    >
      <div className="rd-quote-flex rd-quote-flex-row rd-quote-justify-start">
        {s.picture && (
          <img className="quote-cart-issue-img" alt="P" src={s.picture} />
        )}
        <div className="rd-quote-flex rd-quote-flex-col rd-quote-ml-2 rd-quote-text-gray-800 rd-quote-text-sm rd-quote-font-normal">
          {s.name}
          {/* <span className="rd-quote-text-gray-500 rd-quote-text-sm rd-quote-font-normal">
            Fix cracked back glass
          </span> */}
        </div>
      </div>
      <div className="rd-quote-flex rd-quote-flex-row rd-quote-justify-between">
        {/* price commented */}
        {userSettings?.isPricesEnabled && (
          <div className="rd-quote-bg-sky-100 rd-quote-h-fit rd-quote-rounded">
            <div className="rd-quote-text-center rd-quote-text-sky-700 rd-quote-text-xs rd-quote-font-medium rd-quote-font-['SF Pro Text'] rd-quote-p-1 rd-quote-leading-none">
              {s.price
                ? `${countryDetails?.currency_symbol || "$"}` +
                  s.price?.toFixed(2)
                : "N/A"}
            </div>
          </div>
        )}

        <span
          onClick={() => deleteAndUpdatePrice(s)}
          className="rd-quote-ml-2 rd-quote-text-gray-500 hover:rd-quote-text-red-600 rd-quote-cursor-pointer"
        >
          {currentState === DISPLAYING_PAGES.PRODUCT && (
            <IconTrash className="rd-quote-w-5 rd-quote-h-5" />
          )}
        </span>
      </div>
    </div>
  ));
};

export default IssueList;
