import { yupResolver } from "@hookform/resolvers/yup";
import { IconArrowLeft } from "@tabler/icons-react";
import AppContext from "Context/appContext";
import { DISPLAYING_PAGES, PAGES, PROGRESS_STATE } from "constants";
import Bookinglist from "containers/BookingList";
import Cart from "containers/Cart";
import Completion from "containers/Completion";
import ContactDeatils from "containers/ContactDetails";
import ProductList from "containers/ProductList";
import { useEffect, useMemo, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ClipLoader } from "react-spinners";
// import CryptoJS from 'crypto-js'

import { useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup
  .object({
    name: yup.string().required("Name is Required"),
    model: yup.string().required("Model is Required"),
    email: yup.string().email("Invalid Email").required("Email is Required"),
    phone: yup
      .string()
      .required("Phone Number is Required")
      .test("phone", "Invalid Phone Number", (value) => {
        if (value) {
          return isValidPhoneNumber(value);
        }
        return true;
      }),
  })
  .required();

const AppManager = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors: formErrors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [productCartDetail, setProductCartDetail] = useState(null);
  const [currentState, setCurrentState] = useState("PRODUCT");
  const [allProducts, setAllProducts] = useState([]);
  const [progressState, setProgressState] = useState(0);
  const [products, setProducts] = useState([]);
  const [formdata, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    notes: "",
    option: "",
    model: "",
  });
  const [notificationWay, setNotificationWay] = useState("");
  const [appointmentDetail, setAppointmentDetail] = useState({
    date: "",
    start: "",
    end: "",
  });
  const [loading, setLoading] = useState(true);
  const [fetchTimeLoading, setFetchTimeLoading] = useState(true);
  const [prevState, setPrevState] = useState([]);
  const [currentModelsServices, setCurrentModelsServices] = useState([]);
  const [isMissedItem, setIsMissedItem] = useState(null);
  // const [storeData, setStoreData] = useState(null);
  const [selectedStoreDetails, setSelectedStoreDetails] = useState(null);
  const [allServices, setAllServices] = useState([]);
  const [userSettings, setUserSettings] = useState({});
  const [productCartData, setProductCartData] = useState(null);
  const [allServicesForSubId, setAllServicesForSubId] = useState([]);
  const [encryptionKey, setEncryptionKey] = useState(null);
  const [countryDetails, setCountryDetails] = useState(null);
  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [reactHookFormData, setReactHookFormData] = useState({
    control: control,
    register: register,
    handleSubmit: handleSubmit,
    watch: watch,
    formErrors: formErrors,
    reset: reset,
  });
  const [buttonClicked, setButtonClicked] = useState(null);
  const [height, setHeight] = useState(window.innerHeight);
  const [widthProductSection, setWidthProductSection] = useState(0);
  const [defaultStore, setDefaultStore] = useState(null);

  const updateDimensions = () => {
    setHeight(window.innerHeight);
  };

  const now = useMemo(
    () => ((progressState + 1) / PAGES.length) * 100,
    [progressState]
  );

  const saveState = async () => {
    if (productCartDetail?.skipForOthers && progressState === 3) {
      setProgressState(0);
      setCurrentState(DISPLAYING_PAGES.PRODUCT);
      setProducts(allProducts);
      setProductCartDetail(null);
      return;
    }
    if (progressState === 0) return;
    else if (progressState === 4) {
      setCurrentState(DISPLAYING_PAGES.PRODUCT);
    } else if (progressState === 5) {
      setCurrentState(DISPLAYING_PAGES.FORM);
      setProgressState(progressState - 1);
      setDefaultStore(null);
      return;
    } else if (progressState === 6) {
      setCurrentState(DISPLAYING_PAGES.BOOKING);
      setProgressState(progressState - 1);
      return;
    } else if (progressState === 3) {
      setProductCartDetail(null);
      setProductCartData(null);

      isMissedItem
        ? setCurrentModelsServices(products)
        : setCurrentModelsServices(prevState[progressState - 2][0].services);
    } else if (progressState === 2 && isMissedItem) {
      setProgressState(progressState - 2);
      setProducts(prevState[progressState - 2]);
      setIsMissedItem(null);
      return;
    }
    setProgressState(progressState - 1);
    const getData = prevState[progressState - 1];
    setProducts(getData);

    // Function to update width of product section
    const updateWidthProductSection = () => {
      const productSection = document.getElementById("product-section");
      if (productSection) {
        setWidthProductSection(productSection.offsetWidth);
      }
    };
    setTimeout(() => {
      updateWidthProductSection();
    }, 1);
  };

  const defaultFrameId =
    "My9TWjljRjY1V3F6c2xkZkxTRkkrazVYazNka1hITXBwSHVENXhSUjJ2YUtrWE5wb2s4SzNVZnpRWVg5SnZUUUJoRFN3QS9vZTJmeERWTXNjR3BZbHd5U3JTU3EwVVpyR0d3Mjg1L3hpY0U9";

  useEffect(() => {
    var url = new URL(window.location.href);
    var params = new URLSearchParams(url.search);
    var frameId = params.get("frameId");

    const encrypted = decodeURIComponent(frameId ? frameId : defaultFrameId);
    setEncryptionKey(encrypted);

    // const key = 'rd-appointment-pro-webchat-111';
    // const decryptedBytes = CryptoJS.AES.decrypt(encrypted, key).toString(
    //   CryptoJS.enc.Utf8
    // );

    // const [sub_id, store_id, store_name, server] = JSON.parse(decryptedBytes);
    // setStoreData({ sub_id, store_id, store_name, server });

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [encryptionKey, height]);

  return (
    <>
      <div className="quote-row md:rd-quote-pl-[50px] xs:rd-quote-pl-[25px] rd-quote-items-center rd-quote-pr-[50px]">
        {/* Hide the Back Button on condition */}
        {!loading && (
          <span className="rd-quote-w-[10%]" onClick={() => saveState()}>
            {PROGRESS_STATE.includes(progressState) &&
              currentState !== DISPLAYING_PAGES.COMPLETION && (
                <span className="quote-header-img rd-quote-cursor-pointer rd-quote-cursor-pointer">
                  <IconArrowLeft />
                </span>
              )}
          </span>
        )}
        <div
          className={`${loading ? "rd-quote-w-full" : "rd-quote-w-[80%]"} ${
            PAGES[progressState] !== "Category" ? "quote-card-width" : ""
          } rd-quote-flex rd-quote-flex-col rd-quote-justify-center rd-quote-items-center`}
        >
          <div className="rd-quote-w-4/5 rd-quote-text-center rd-quote-font-medium rd-quote-pt-5 sm:rd-quote-text-[16px] md:rd-quote-text-[18px] lg:rd-quote-text-[28px] lg:rd-quote-pt-3">
            {/* Get Instant Price Quote */}
          </div>
          <div
            className={`rd-quote-text-[14px] lg:rd-quote-text-[22px] ${
              PAGES[progressState] === "Services" ||
              PAGES[progressState] === "Contact Details"
                ? "quote-padding-left"
                : ""
            }`}
          >
            {PAGES[progressState] === "Category"
              ? "Select Category"
              : PAGES[progressState] === "Brands"
              ? "Select Brand"
              : PAGES[progressState] === "Models"
              ? "Select Model"
              : PAGES[progressState] === "Services"
              ? "Select the issues you are facing with your device"
              : PAGES[progressState] === "Contact Details"
              ? "Please provide your Contact Information"
              : PAGES[progressState]}
          </div>
        </div>
      </div>
      <div
        className={`quote-main-bg ${
          height < 700
            ? "lg:rd-quote-overflow-y-scroll lg:rd-quote-h-[73vh]"
            : ""
        }`}
      >
        <div className="quote-colum">
          {currentState !== DISPLAYING_PAGES.COMPLETION && (
            <>
              <div className="quote-row-justify rd-quote-mb-2">
                <p className="quote-text2">{PAGES[progressState]}</p>
                {(progressState+1) !== 7 && (
                  <p className="quote-text1">
                    {progressState + 1} of <span>6</span>
                  </p>
                )}
                {(progressState+1) === 7 && (
                  <p className="quote-text1">
                    Finalising
                  </p>
                )}
              </div>
              <ProgressBar className="rd-quote-bg-gray-200" now={now} />
            </>
          )}
          {products?.length === 0 && !loading && (
            <div className="rd-quote-my-4">
              <p className="quote-text1 rd-quote-text-center">
                No available services found.{" "}
              </p>
            </div>
          )}
          {loading && (
            <div className="rd-quote-flex rd-quote-h-screen rd-quote-items-center rd-quote-justify-center rd-quote-mx-auto">
              <ClipLoader color={"#000"} loading={loading} size={70} />
            </div>
          )}
          <div className="quote-row rd-quote-justify-center">
            <AppContext.Provider
              value={{
                productCartDetail,
                setProductCartDetail,
                productCartData,
                setProductCartData,
                products,
                setProducts,
                setCurrentState,
                currentState,
                setProgressState,
                formdata,
                setFormData,
                notificationWay,
                setNotificationWay,
                allProducts,
                setAllProducts,
                setLoading,
                prevState,
                setPrevState,
                progressState,
                currentModelsServices,
                setCurrentModelsServices,
                appointmentDetail,
                setAppointmentDetail,
                fetchTimeLoading,
                setFetchTimeLoading,
                isMissedItem,
                setIsMissedItem,
                // storeData,
                selectedStoreDetails,
                setSelectedStoreDetails,
                allServices,
                setAllServices,
                userSettings,
                setUserSettings,
                allServicesForSubId,
                setAllServicesForSubId,
                encryptionKey,
                setEncryptionKey,
                errors,
                setErrors,
                reactHookFormData,
                setReactHookFormData,
                buttonClicked,
                setButtonClicked,
                widthProductSection,
                setWidthProductSection,
                defaultStore,
                setDefaultStore,
                countryDetails,
                setCountryDetails,
              }}
            >
              {/* Show dynamic pages */}
              {/* {currentState === DISPLAYING_PAGES.PRODUCT && storeData?.sub_id && <ProductList />} */}
              {currentState === DISPLAYING_PAGES.PRODUCT && encryptionKey && (
                <ProductList />
              )}
              {currentState === DISPLAYING_PAGES.FORM && <ContactDeatils />}
              {currentState === DISPLAYING_PAGES.BOOKING && <Bookinglist />}
              {currentState === DISPLAYING_PAGES.COMPLETION && <Completion />}
              {((productCartDetail && products?.length && progressState >= 3) ||
                (productCartData &&
                  products?.length &&
                  progressState >= 3)) && <Cart />}
            </AppContext.Provider>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppManager;
