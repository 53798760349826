export function combineDateWithTime(dateTimeString, anotherTime) {
    if (dateTimeString === '' || anotherTime === '') return ''
    const dateObject = new Date(dateTimeString);

    // Extracting date parts (YYYY-MM-DD) from the Date object
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Adding 1 as month index starts from 0
    const day = String(dateObject.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`; // Output: "2024-01-03"
    const updatedDateAndTime = `${formattedDate}`;
    return updatedDateAndTime
}

export const isKeyValueEmpty = (obj) =>
    Object.values(obj).every((value) => value !== "");



export function hasFalsyValue(obj) {
    return Object.keys(obj).length === 0
}

export function findAllEmptyKeys(obj) {
    const emptyKeys = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && !obj[key]) {
            emptyKeys.push(key);
        }
    }
    return emptyKeys.length > 0 ? emptyKeys : null;
}

// Function to convert 24-hour format to 12-hour format
export function formatTo12Hour(time24) {
    const [hour, minute] = time24.split(":");
    const hour12 = (hour % 12) || 12;
    const ampm = hour >= 12 ? "PM" : "AM";
    return `${hour12}:${minute} ${ampm}`;
  };


