import AppContext from "Context/appContext";
import { useContext, memo } from "react";
import { DISPLAYING_PAGES } from "constants";

const Button = (props) => {
  const { disabled, text, setWidth, typeA, submit, setServiceArray } = props;
  const { setCurrentState, setProgressState } = useContext(AppContext)

  
  const toggleState = ()=> {
    switch(text){
     case 'Continue':
     return DISPLAYING_PAGES.FORM
     case 'Get Quote':
     return DISPLAYING_PAGES.BOOKING
     case 'Book an Appointment':
      return DISPLAYING_PAGES.BOOKING
     default:
     return DISPLAYING_PAGES.PRODUCT
    }
  }

  const handleSubmission = ()=>{
  if(submit !== undefined){
    submit()
  }
  } 

  const handleState = ()=> {
    if(setServiceArray !== undefined){
      setServiceArray()
    }
  }

  return (
    <button onClick={()=> {setCurrentState(toggleState);setProgressState(prevState => prevState === 6 ? prevState: prevState+1);handleSubmission(); handleState()}}
      disabled={disabled}
    // ${ setWidth ? 'rd-quote-w-[48%]' : 'rd-quote-w-full'} 
      className={`rd-quote-w-full rd-quote-mt-2 rd-quote-py-2 ${
        !disabled
          ? typeA ? "rd-quote-border rd-quote-border-gray-300 rd-quote-text-gray-700" 
          :  "rd-quote-bg-teal-600 rd-quote-text-white" 
          : "rd-quote-bg-gray-100 rd-quote-text-gray-300"
      } rd-quote-rounded-md rd-quote-text-sm rd-quote-font-medium`}
    >
      {text}
    </button>
  );
};

export default memo(Button);
