import AppContext from 'Context/appContext';
import { fetchSettingsForSubId } from 'api';
import Card from 'components/Card';
import Input from 'components/Input';
import 'containers/ProductList/index.css';
import { memo, useContext, useEffect, useState } from 'react';

let load = true;

const ProductList = () => {
  const {
    productCartDetail,
    setProductCartDetail,
    productCartData,
    setProgressState,
    products,
    setProducts,
    setAllProducts,
    setLoading,
    prevState,
    setPrevState,
    progressState,
    currentModelsServices,
    setCurrentModelsServices,
    setIsMissedItem,
    // storeData,
    allServices,
    setAllServices,
    setUserSettings,
    allServicesForSubId,
    setAllServicesForSubId,
    widthProductSection,
    setWidthProductSection,
    encryptionKey
  } = useContext(AppContext);

  const [search, setSearch] = useState('');
  const [searchProducts, setSearchProducts] = useState([]);

  const base_url = process.env.REACT_APP_APPOINTMENT_PRO_API_URL;

  useEffect(() => {

    // Function to update width of product section
    const updateWidthProductSection = () => {
      const productSection = document.getElementById('product-section');
      if (productSection) {
        setWidthProductSection(productSection.offsetWidth);
      }
    };

    // Initial call to set div width
    updateWidthProductSection()

    // Event listener for window resize
    window.addEventListener('resize', updateWidthProductSection);

    const fetchData = async () => {
      if (products.length > 0 || !load) return;
      setLoading(true);
      try {
        load = false;
        const response = await fetch(
          `${base_url}/category/all/users?q_=${encryptionKey}`
        );
        const data = await response.json();
        let newCategories = data.map((category) =>
          category.brands.filter((brand) => brand.isFavorite)
        );
        const filteredData = [...data, ...newCategories];
        setProducts(filteredData.flat());
        setAllProducts(filteredData.flat());
        setSearchProducts(filteredData.flat());

        const allServices = await fetchSettingsForSubId(
          `${base_url}/service/all?q_=${encryptionKey}`
        );
        setAllServicesForSubId(allServices);
        const settingsData = await fetchSettingsForSubId(
          `${base_url}/settings?q_=${encryptionKey}`
        );
        setUserSettings(settingsData);
      } catch (error) {
        console.log(error);
      } finally {
        updateWidthProductSection()
        setLoading(false);
      }
    };
    fetchData();
    // setProducts(prevState[progressState])
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', updateWidthProductSection);
    
  }, [widthProductSection]);

  const filteredProducts = (currentIndex, productName) => {
    if (productName === 'Others') {
      const s = allServicesForSubId.map((c) => ({
        ...c,
        active: false,
      }));
      setProducts(s);
      setProgressState(3);
      setCurrentModelsServices([]);
      setProductCartDetail({
        ...productCartDetail,
        model: {},
        services: [],
        skipForOthers: true,
      });
    }

    let selectedItem = search ? searchProducts[currentIndex] : products[currentIndex];
    if (progressState !== 3) {
      setPrevState({ ...prevState, [progressState]: products });
    }

    setProgressState((prevState) => {
      if (prevState === 3) return prevState;
      if (prevState === 0 && selectedItem.isFavorite) {
        const category = products?.find(
          (product) => product._id === selectedItem.category
        );
        setProductCartDetail({
          ...productCartDetail,
          category,
          brand: selectedItem,
        });
        setIsMissedItem({ ...selectedItem });
        return prevState + 2;
      } else {
        return prevState + 1;
      }
    });
    if (selectedItem?.brands) {
      // If brands exist, return the brands array
      setProductCartDetail({ ...productCartDetail, category: selectedItem });
      setProducts(selectedItem.brands);
    } else if (selectedItem?.models) {
      setProductCartDetail({ ...productCartDetail, brand: selectedItem });
      // If models exist, return the models array
      setCurrentModelsServices(selectedItem.services);
      setAllServices(selectedItem.services);
      setProducts(selectedItem.models);
    } else if (currentModelsServices?.length > 0) {
      // If services exist, return the services array
      setProductCartDetail({ ...productCartDetail, model: selectedItem });
      const filteredServices = allServices
        .filter((service) => service.model._id === selectedItem._id)
        .map((c) => ({ ...c, active: false }));
      setProducts(filteredServices);
      setCurrentModelsServices([]);
    } else {
      const services = productCartDetail?.services
        ? productCartDetail?.services
        : [];

      const key = selectedItem?.hasOwnProperty('_id') ? '_id' : 'id';
      const newItem =
        services?.findIndex((s) => s[key] === selectedItem[key]) >= 0;

      if (selectedItem?.sale_price) {
        const price = selectedItem?.sale_price.startsWith('0')? selectedItem?.retail_price : selectedItem?.sale_price
        selectedItem['price'] = parseFloat(price)
      }

      let totalPrice =
        (productCartDetail?.totalPrice === undefined || productCartDetail?.totalPrice === 0)
          ? selectedItem?.price
          : selectedItem?.price + productCartDetail?.totalPrice;

      selectedItem['active'] = !selectedItem?.active;

      if (newItem) {
        //if the item is already existed
        let totalPrice = productCartDetail?.totalPrice - selectedItem.price;
        if (isNaN(totalPrice) || totalPrice === undefined) {
          totalPrice = 0
        }
        const updatedList = productCartDetail.services.filter(
          (p) => p[key] !== selectedItem[key]
        );
        setProductCartDetail({
          ...productCartDetail,
          totalPrice,
          services: updatedList,
        });
        setProducts([...products]);
        return;
      }
      // data will get from our database
      if (!productCartData && !productCartDetail?.model) {
        if (selectedItem?.model) {
          setProductCartDetail({
            ...productCartDetail,
            totalPrice,
            services: [...services, selectedItem],
          });
        }
      } else {
        // data will get from our repairdesk database
        setProductCartDetail({
          ...productCartDetail,
          totalPrice,
          services: [...services, selectedItem],
        });
      }
    }
  };

  const getFilteredProducts = () => {
    if (!search) return products;
    return products?.filter((p) =>
      p.name.toLowerCase().includes(search.toLowerCase())
    );
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    setSearchProducts(
      products?.filter((p) =>
        p.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const displayedProducts = [
    ...getFilteredProducts(),
    { name: 'Others', _id: 'others' },
  ];

  return (
    <>
      {products?.length > 0 && (
        <div
          className={`quote-product-main ${productCartDetail?.model ? 'quote-card-width' : 'rd-quote-w-[100%]'
            } `}
        >
          <Input
            placeholder={'Search here...'}
            isShowIcon={true}
            value={search}
            handleChange={handleChange}
          />

          {displayedProducts?.length > 0 ? (
            <div
              id="product-section"
              className={`quote-product-section quote-scroller-hide quote-bottom-padding ${productCartDetail?.model
                ? 'rd-quote-gap-x-[16px] issues-max-height'
                : 'base-max-height'
                } ${(progressState !== 3 && widthProductSection > 1300) ? '!rd-quote-grid-cols-4' : ''}`}
            >
              {displayedProducts?.map((p, index) => (
                <Card
                  key={p._id || index}
                  index={index}
                  product={p}
                  filteredProducts={filteredProducts}
                  setProducts={setProducts}
                  setSearch={setSearch}
                />
              ))}
            </div>
          ) : (
            <div className='rd-quote-w-[100%] rd-quote-flex rd-quote-justify-center rd-quote-items-center'>
              <h4 className='rd-quote-my-auto rd-quote-mt-20'>
                No Results Found
              </h4>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default memo(ProductList);
