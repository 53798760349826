import { IconCalendar } from "@tabler/icons-react";
import AppContext from "Context/appContext";
import { format } from "date-fns";
import { useContext } from "react";

import Button from "components/Button";
import IssueList from "containers/Cart/IssueList";
import "containers/Completion/index.css";
import { isKeyValueEmpty, formatTo12Hour } from "utils";

const Completion = () => {
  const {
    productCartDetail,
    setProducts,
    allProducts,
    setProgressState,
    setFormData,
    setProductCartDetail,
    appointmentDetail,
    formdata,
    notificationWay,
    setNotificationWay,
    productCartData,
    setProductCartData,
    buttonClicked,
    reactHookFormData,
    setDefaultStore,
    setSelectedStoreDetails
  } = useContext(AppContext);

  let m_id = null;
  let model = null;

  if (productCartData && productCartData?.device_name) {
    m_id = String(productCartDetail?.services[0]?.device_id);
    model = productCartDetail?.brand?.models.find((ele) => ele.rd_device_id === m_id);
  } else if (!productCartData) {
    m_id = String(productCartDetail?.services[0]?.model);
    model = productCartDetail?.brand?.models.find((ele) => ele._id === m_id);
  }

  const resetStates = () => {
    setProducts(allProducts);
    setProgressState(0);
    setFormData({ name: "", phone: "", email: "", notes: "", option: "", model: "" });
    setProductCartDetail(null);
    setProductCartData(null);
    setDefaultStore(null);
    setNotificationWay('');
    setSelectedStoreDetails(null);
    reactHookFormData.reset()
  };

  return (
    <>
      <div className="quote-end !rd-quote-h-[610px] quote-mobile-reciept">
        <div className="quote-end-width">
          <div className="quote-col rd-quote-justify-center rd-quote-text-center rd-quote-items-center rd-quote-gap-1 rd-quote-mb-4">
            <span className="rd-quote-inline-block">
              <img
                className="rd-quote-w-[125px] rd-quote-h-[110px]"
                alt={"p"}
                src="/CircleCheck.png"
              />
            </span>
            <div className="tquote-text3 rd-quote-mt-1 rd-quote-text-center">
              Thanks for Completing the Inquiry Form!
            </div>
            <div className="quote-text1 rd-quote-text-center rd-quote-pr-6 rd-quote-pl-6 rd-quote-mt-1">
            
            {(buttonClicked === 'appointment' && (notificationWay === 'sms' || notificationWay === 'email'))
              ? `Your appointment has been booked! Please check your ${notificationWay === 'email' ? 'email' : 'SMS'} for more information. We are excited to make your phone brand-new.`
              : (buttonClicked === 'quote' && (notificationWay === 'sms' || notificationWay === 'email'))
              ? `Your Quote is on the way! Kindly check your ${notificationWay === 'email' ? 'email address' : 'SMS'}. We’re excited to serve you. `
              : `Our team will be in touch shortly to provide you with the details of the ${buttonClicked}.`
            }
            </div>
          </div>
          <div className="quote-cart-header">Information</div>
          {/* Item */}
          <div className="quote-row quote-row-gap quote-direction-column quote-gap-mobile-screen">
            {!formdata.model &&
              <div className="quote-cart-item">
                <div className="quote-cart-item-section">
                  {productCartDetail?.brand?.picture && (
                    <div className="quote-cart-item-img">{
                      productCartDetail?.brand?.picture && <img
                        className="w- h-7"
                        alt="P"
                        src={productCartDetail?.brand?.picture}
                      />
                    }

                    </div>
                  )}
                  <div className="quote-cart-item-title ">
                    <div className="quote-text1">Brand</div>
                    <div className="quote-text1 rd-quote-font-normal rd-quote-truncate rd-quote-max-w-[150px]">
                      {" "}
                      {productCartDetail?.brand?.name || productCartDetail?.services[0]?.manufacturer_name || formdata.model || 'Others'}
                    </div>
                  </div>

                </div>
              </div>
            }

            <div className="quote-cart-item">
              <div className="quote-cart-item-section">
                {(productCartDetail?.model?.picture || model?.picture) &&
                  <div className="quote-cart-item-img">
                    <img
                      className="w- h-7"
                      alt="P"
                      src={productCartDetail?.model?.picture ? productCartDetail?.model?.picture : model?.picture}
                    />
                  </div>}
                <div className="quote-cart-item-title ">
                  <div className="quote-text1">Model</div>
                  <div className="quote-text1 rd-quote-font-normal rd-quote-truncate rd-quote-max-w-[150px]">
                    {productCartDetail?.model?.name || productCartDetail?.services[0]?.device_name || formdata?.model || model?.name || 'Others'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isKeyValueEmpty(appointmentDetail) && (
            <div className="quote-cart-item rd-quote-mt-4">
              <div className="quote-cart-item-section">
                <div className="quote-cart-item-img">
                  <IconCalendar className="rd-quote-text-gray-500" />
                </div>
                <div className="quote-cart-item-title ">
                  <div className="quote-text1">Appointment Date & Time</div>
                  <div className="quote-text1 rd-quote-font-normal rd-quote-truncate rd-quote-max-w-[250px]">
                    {format(new Date(appointmentDetail?.date), "dd-MM-yyyy")},{" "}
                    {/* {appointmentDetail?.start} - {appointmentDetail?.end} */}
                    {appointmentDetail?.start && formatTo12Hour(appointmentDetail.start)} - {appointmentDetail?.end && formatTo12Hour(appointmentDetail.end)}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* issues header */}
          {productCartDetail?.services && (
            <>
              <div className="quote-cart-issue-header">
                <div className="quote-text1">
                  Issues({productCartDetail?.services?.length})
                </div>
                {/* <div className="rd-quote-text-teal-600 rd-quote-text-sm rd-quote-font-medium rd-quote-flex">
                View Issues
                <span>
                  <IconChevronUp />
                </span>
              </div> */}
              </div>
              <div className="quote-cart-issue-scroll !rd-quote-max-h-[90px] lg:!rd-quote-max-h-[172px]">
                <IssueList />
              </div>
            </>
          )}
        </div>
        {/* bottom  */}
        <div className="rd-quote-flex rd-quote-mt-2 rd-quote-flex-col rd-quote-justify-between">
          {/* <div className="rd-quote-flex rd-quote-flex-row rd-quote-justify-between rd-quote-inline-flex rd-quote-items-center rd-quote-bg-gray-100 rd-quote-rounded rd-quote-px-2 ">
          <p className="rd-quote-text-gray-800 rd-quote-text-base rd-quote-font-normal rd-quote-leading-Normal">
            Sub Total
          </p>
          <p className="rd-quote-text-gray-800 rd-quote-text-base rd-quote-font-medium  rd-quote-leading-normal">
            ${productCartDetail?.totalPrice === undefined ? 0 : productCartDetail?.totalPrice}
          </p>
        </div> */}
          <Button text={"Get Another Quote"} submit={resetStates} />
        </div>
      </div>
    </>
  );
};

export default Completion;
