import { IconSearch } from '@tabler/icons-react';

const FormInput = (props) => {
    const { isShowIcon, placeholder, register, name, type = 'text' } = props;

    return (
        <div
            className={`quote-border-styling rd-quote-flex rd-quote-flex-row rd-quote-justfiy-start ${isShowIcon
                    ? 'lg:rd-quote-w-[270px] sm:rd-quote-w-full rd-quote-mt-4'
                    : 'quote-input-width rd-quote-h-[40px] rd-quote-mt-2'
                }  rd-quote-py-1.5 rd-quote-px-1 rd-quote-bg-white rd-quote-rounded-md`}
        >
            {isShowIcon && <IconSearch color='gray' width={16} height={16} />}
            <input
                {...register(name)}
                className='rd-quote-text-sm rd-quote-border-none  focus:rd-quote-outline-none rd-quote-shadow-none rd-quote-ml-2 rd-quote-ring-0 focus:rd-quote-ring-0 rd-quote-placeholder-gray-400 rd-quote-text-gray-700 rd-quote-font-normal rd-quote-leading-tight rd-quote-w-full rd-quote-mr-1'
                placeholder={placeholder}
                type={type}
            />
        </div>
    );
};

export default FormInput;
