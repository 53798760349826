import { IconClock, IconMapPin } from "@tabler/icons-react";
import { formatTo12Hour } from "utils";
import AppContext from "Context/appContext";
import "containers/BookingList/index.css";
import { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import BookingDetails from "./BookingDetails";

const Bookinglist = () => {
  const [toggle, setToggle] = useState(false);
  const [selectedBookingPage, setSelectedBookingPage] = useState({});
  const [bookingList, setBookingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(300);
  const { setSelectedStoreDetails } = useContext(AppContext);

  // const { storeData
  // } = useContext(AppContext)
  const { encryptionKey, setDefaultStore } = useContext(AppContext)

  const key = "AIzaSyBlqrSdiC-YtE8DGNRJXRGXyoFQiHGsER4";

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // set width for iframe google map
    const updateWidth = () => {
      // Set the width to 200 for screens with a width less than 600px
      if (window.innerWidth <= 768) {
        setWidth(150);
      } else {
        setWidth(300); // Set the default width for larger screens
      }
    };

    // Initial width update
    updateWidth();

    // Update width on window resize
    window.addEventListener('resize', updateWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateWidth);
    };


  }, []);


  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_APPOINTMENT_PRO_API_URL}/settings/get-all-stores`,
        {
          method: "POST",
          body: JSON.stringify({
            q_: encryptionKey
            // isGeneric: "",
            // server: storeData?.server,
            // sub_id: storeData?.sub_id,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await response.json();
      setBookingList(data.stores);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  

  const toggleBookingDetail = (bookingDetail) => {
    setSelectedBookingPage(bookingDetail);
    setToggle(true);
    setDefaultStore(true);
    setSelectedStoreDetails({ store_id: bookingDetail.store_id, store_name: bookingDetail.name })
  };

  const isStoreOpen = (startTime, endTime) => {

    const now = new Date();
    const start = new Date().setHours(...startTime?.split(":"));
    const end = new Date().setHours(...endTime?.split(":"));

    return start < end
      ? now >= start && now <= end
      : !(now < end || now > start);
  };

  if (loading) {
    return (
      <div className="rd-quote-flex rd-quote-h-screen rd-quote-items-center rd-quote-justify-center rd-quote-mx-auto">
        <ClipLoader color="dark-green" loading={loading} size={70} />
      </div>
    );
  }

  return (
    <div className="quote-booking-main quote-colum quote-scroll-instant-price quote-scroller-hide rd-quote-w-[75%] rd-quote-h-[535px] rd-quote-max-h-[620px]">
      {!toggle &&
        bookingList.length > 0 &&
        bookingList.map((b) => (
          <div
            onClick={() => toggleBookingDetail(b)}
            key={b.id}
            className="quote-booking-card rd-quote-border rd-quote-rounded-md rd-quote-border-gray-300 rd-quote-shadow"
          >
            <div className="quote-row-justify quote-direction-column">
              <div className="quote-colum">
                <div className="quote-text2 rd-quote-mb-2">{b.name}</div>
                <div className="quote-row rd-quote-mb-2">
                  <IconMapPin className="rd-quote-w-5 rd-quote-h-5  rd-quote-text-gray-500" />
                  <div className="quote-text1 rd-quote-ml-2">
                    {b.address !== "" ? b.address.concat(", ") : ""}
                    {b.city}
                    {b.state}
                    {b.postcode !== ""
                      ? b.country !== ""
                        ? b.postcode.concat(", ")
                        : b.postcode
                      : ""}
                    {b.country}
                  </div>
                </div>
                <div className="quote-row">
                  <IconClock className="rd-quote-w-5 rd-quote-h-5 rd-quote-text-gray-500" />
                  <span
                    className={`${isStoreOpen(b.start_time, b.end_time)
                      ? "rd-quote-text-green-600"
                      : "rd-quote-text-rose-600"
                      } rd-quote-text-sm rd-quote-font-normal
                 rd-quote-font-['SF Pro Text'] rd-quote-leading-5 rd-quote-mx-2`}
                  >
                    {isStoreOpen(b.start_time, b.end_time) ? "Open" : "Closed"}
                  </span>
                  <span className="quote-text1">-</span>
                  <div className="quote-text1 rd-quote-ml-2">
                    {/* Closed at {b.end_time.substring(0, 5)}pm */}
                    Closed at {b?.end_time && formatTo12Hour(b.end_time)}
                  </div>
                </div>
              </div>
              <div className="quote-margin-top">
                <iframe
                  title="google map"
                  src={`https://www.google.com/maps/embed/v1/place?key=${key}&q="+ ${encodeURIComponent(
                    b.address
                  )}"`}
                  width={width}
                  height="140"
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        ))}
      {toggle && selectedBookingPage && (
        <BookingDetails
          bookingDetail={selectedBookingPage}
          key={key}
          getStoreTime={isStoreOpen}
        />
      )}
    </div>
  );
};

export default Bookinglist;
