import { IconMail, IconMessage, IconPhoneCall } from "@tabler/icons-react";
import AppContext from "Context/appContext";
import Card from "components/Card";
import FormInput from "components/FormInput";
import "containers/ContactDetails";
import "containers/ContactDetails/index.css";
import { useContext, useEffect, useState } from "react";
import { Controller, useFormState } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const menuOption = [
  {
    id: 1,
    name: "SMS (Instant)",
    icon: <IconMessage className="quote-contact-icon" />,
    value: "sms",
    active: false,
  },
  {
    id: 2,
    name: "Email (Instant)",
    icon: <IconMail className="quote-contact-icon" />,
    value: "email",
    active: false,
  },
  {
    id: 3,
    name: "Phone",
    icon: <IconPhoneCall className="quote-contact-icon" />,
    value: "phone",
    active: false,
  },
];

const ContactDeatils = () => {
  const {
    formdata,
    setFormData,
    productCartDetail,
    progressState,
    setErrors,
    reactHookFormData,
    countryDetails,
  } = useContext(AppContext);

  const { register, handleSubmit, watch, control, formErrors } =
    reactHookFormData;
  const [isWebView, setIsWebView] = useState(false);

  const updateStatus = (index) => {
    const selectedItem = menuOption[index];
    // eslint-disable-next-line array-callback-return
    menuOption.filter((m) => {
      if (m.id === selectedItem.id) {
        m.active = true;
      } else {
        m.active = false;
      }
    });
    setFormData({
      ...formdata,
      option: selectedItem.active ? selectedItem.value : "",
    });
  };

  // Function to detect if the user is on web view
  const detectWebView = () => {
    const screenWidth = window.innerWidth;
    setIsWebView(screenWidth >= 768); // Adjust breakpoint as needed
  };

  // Detect web view on component mount
  useEffect(() => {
    detectWebView();
    window.addEventListener("resize", detectWebView);

    return () => {
      window.removeEventListener("resize", detectWebView);
    };
  }, []);

  const submitHandler = (data) => {};

  watch((value, { name, type }) => {
    if (type === "change") {
      setFormData((prev) => ({
        ...prev,
        [name]: value[name],
      }));
    }
  });

  useEffect(() => {
    setErrors(formErrors);
  }, [formErrors, setErrors]);

  useEffect(() => {
    menuOption.forEach((m) => {
      m.active = false;
    });
  }, []);

  const { errors } = useFormState({ control: control });

  return (
    <div className="quote-colum rd-quote-w-full quote-scroller-hide md:rd-quote-w-[75%] quote-scroll-instant-price">
      <div className="rd-quote-mt-4 rd-quote-mb-4">
        <form
          className="quote-contact-form"
          onSubmit={handleSubmit(submitHandler)}
        >
          <div className="rd-quote-flex quote-direction-column rd-quote-flex-wrap">
            {productCartDetail?.skipForOthers && progressState === 4 && (
              <label className="quote-text2 rd-quote-mr-4">
                {isWebView && <span> Model </span>}
                {isWebView && (
                  <span className="rd-quote-text-red-500 rd-quote-text-sm rd-quote-font-medium rd-quote-font-['SF Pro Text'] rd-quote-leading-tight">
                    *
                  </span>
                )}
                <FormInput
                  register={register}
                  name="model"
                  placeholder="Enter Model"
                />
                <p className="rd-quote-text-red-500 rd-quote-mt-1">
                  {errors?.model?.message}
                </p>
              </label>
            )}
            <label className="quote-text2 rd-quote-mr-4">
              {isWebView && <span> Name </span>}
              {isWebView && (
                <span className="rd-quote-text-red-500 rd-quote-text-sm rd-quote-font-medium rd-quote-font-['SF Pro Text'] rd-quote-leading-tight">
                  *
                </span>
              )}

              <FormInput
                register={register}
                name="name"
                placeholder="Enter Name"
              />

              <p className="rd-quote-text-red-500 rd-quote-mt-1">
                {errors?.name?.message}
              </p>
            </label>

            <label
              className={`quote-text2 rd-quote-mr-4 quote-instant-form-width`}
            >
              {isWebView && <span> Phone </span>}
              {isWebView && (
                <span className="rd-quote-text-red-500 rd-quote-text-sm rd-quote-font-medium rd-quote-font-['SF Pro Text'] rd-quote-leading-tight">
                  *
                </span>
              )}

              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    international
                    // defaultCountry="US"
                    defaultCountry={countryDetails?.country_code}
                    value={value}
                    onChange={onChange}
                    // onBlur={() => {
                    //   if (!value) {
                    //     setError('phone', {
                    //       type: 'manual',
                    //       message: 'Phone number is required.',
                    //     });
                    //   } else if (value && !isValidPhoneNumber(value)) {
                    //     setError('phone', {
                    //       type: 'manual',
                    //       message: 'Invalid phone number.',
                    //     });
                    //   }
                    // }}
                    className="quote-border-styling rd-quote-py-2 rd-quote-pl-3 rd-quote-font-normal md:rd-quote-mt-2 rd-quote-rounded-md focus:rd-quote-outline-none rd-quote-ring-0 focus:rd-quote-border-none focus:rd-quote-ring-0 rd-quote-placeholder-gray-400 rd-quote-text-gray-700 rd-quote-text-sm rd-quote-w-full rd-quote-mr-1 rd-quote-h-[40px]"
                  />
                )}
              />

              <p className="rd-quote-text-red-500 rd-quote-mt-1">
                {errors?.phone?.message}
              </p>
            </label>

            <label className="quote-text2 rd-quote-mr-4">
              {isWebView && <span> Email </span>}
              {isWebView && (
                <span className="rd-quote-text-red-500 rd-quote-text-sm rd-quote-font-medium rd-quote-font-['SF Pro Text'] rd-quote-leading-tight">
                  *
                </span>
              )}

              <FormInput
                register={register}
                name="email"
                placeholder="Enter Email"
                type="email"
              />

              <p className="rd-quote-text-red-500 rd-quote-mt-1">
                {errors?.email?.message}
              </p>
            </label>
          </div>

          <label className="quote-colum rd-quote-text-gray-700 rd-quote-font-medium rd-quote-text-[16px] rd-quote-mt-2 md:rd-quote-mt-4">
            {isWebView && <span> Notes </span>}
            <textarea
              placeholder="Describe the issue you are facing with your device"
              name="notes"
              {...register("notes")}
              className="rd-quote-border rd-quote-py-2 rd-quote-pl-3 rd-quote-font-normal md:rd-quote-mt-2 rd-quote-border-gray-300 rd-quote-rounded-md rd-quote-shadow  focus:rd-quote-outline-none rd-quote-ring-0 focus:rd-quote-ring-0 rd-quote-placeholder-gray-400 rd-quote-text-gray-700 rd-quote-text-sm rd-quote-w-full rd-quote-mr-1"
            />
          </label>
          <p className="quote-text2 rd-quote-mt-5">
            How would you like us to share the update?*
          </p>
          <div className="rd-quote-grid rd-quote-grid-cols-1 md:rd-quote-grid-cols-3 md:rd-quote-grid-flow-col rd-quote-gap-4 quote-alignment-center quote-card-flex">
            {menuOption.map((p, index) => (
              <Card
                key={p.id}
                product={p}
                Icon={p.icon}
                index={index}
                filteredProducts={updateStatus}
              />
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactDeatils;
