import { IconPercentage } from "@tabler/icons-react";
import AppContext from "Context/appContext";
import Button from "components/Button";
import { DISPLAYING_PAGES } from "constants";
import "containers/Cart/index.css";
import { useContext, useEffect, useState } from "react";
import { useFormState } from "react-hook-form";
import { combineDateWithTime, isKeyValueEmpty } from "utils";
import IssueList from "./IssueList";
import axios from "axios";

const Cart = () => {
  const {
    productCartDetail,
    currentState,
    formdata,
    setPrevState,
    products,
    progressState,
    prevState,
    appointmentDetail,
    setLoading,
    // storeData,
    setCurrentState,
    userSettings,
    productCartData,
    setProductCartData,
    encryptionKey,
    reactHookFormData,
    // setFormData,
    buttonClicked,
    setButtonClicked,
    defaultStore,
    selectedStoreDetails,
    setCountryDetails,
    countryDetails,
  } = useContext(AppContext);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  

  // Set previous state
  const setServiceArray = () => {
    setPrevState({ ...prevState, [progressState]: products });
  };

  // Function to detect if the user is on mobile or tablet
  const detectMobileOrTablet = () => {
    const screenWidth = window.innerWidth;
    setIsMobileOrTablet(screenWidth <= 768); // Adjust breakpoint as needed
  };

  // Toggle visibility of modal
  const toggleModal = () => {
    setIsViewOpen(!isViewOpen);
  };

  // Detect mobile or tablet on component mount
  useEffect(() => {
    fetchCountryDetails();
    detectMobileOrTablet();
    window.addEventListener("resize", detectMobileOrTablet);

    return () => {
      window.removeEventListener("resize", detectMobileOrTablet);
    };
  }, []);

  const fetchCountryDetails = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_APPOINTMENT_PRO_API_URL}/settings/store-info`,
      {
        q_: encryptionKey,
      }
    );
    setCountryDetails(data?.data);
  };

  const submitForm = async (clickedKey) => {
    if (clickedKey === "appointments") {
      setButtonClicked("appointment");
      clickedKey = "appointments";
    } else {
      setButtonClicked("quote");
      clickedKey = "quote";
    }
    try {
      setLoading(true);
      setButtonLoader(true);
      const services = productCartDetail?.services.map((item) => {
        const price =
          item.sale_price &&
          typeof item.sale_price === "string" &&
          item.sale_price.startsWith("0")
            ? item.retail_price
            : item.sale_price;
        return {
          rd_issue_id: item.id,
          name: item.name,
          price: item?.price ? item?.price : price,
        };
      });

      const formattedTime = combineDateWithTime(
        appointmentDetail?.date,
        appointmentDetail?.start
      );
      const data = {
        device: productCartDetail?.category?.name || "",
        brand: productCartDetail?.brand?.name || "others",
        model:
          productCartDetail?.model?.name ||
          productCartData?.device_name ||
          formdata?.model,
        rd_device_id: productCartData?.rd_device_id,
        date: formattedTime || "",
        start_time:
          appointmentDetail?.start !== "" ? appointmentDetail?.start : "",
        end_time: appointmentDetail?.end !== "" ? appointmentDetail?.end : "",
        issues: services,
        store_id: selectedStoreDetails.store_id,
        store_name: selectedStoreDetails.store_name,
        // store_id: storeData?.store_id,
        // store_name: storeData?.store_name,
        // sub_id:storeData?.sub_id,
        customer_name: formdata.name,
        customer_email: formdata.email,
        customer_telephone: formdata.phone,
        customer_notes: formdata.notes,
        delivery_option: formdata?.option,
        delivery_status: formdata.status,
        total_price: productCartDetail?.totalPrice || 0,
        q_: encryptionKey,
        config_type: clickedKey,
      };
      // console.log(data);
      const res = await fetch(
        `${process.env.REACT_APP_APPOINTMENT_PRO_API_URL}/lead/save`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const response = await res.json();
      // console.log(response);
      setLoading(false);
      setButtonLoader(false);
      // setProductCartData(null);
      setCurrentState(DISPLAYING_PAGES.COMPLETION);
      // setFormData({
      //   name: '',
      //   phone: '',
      //   email: '',
      //   notes: '',
      //   option: '',
      //   model: '',
      // })
      // reactHookFormData.reset()
    } catch (error) {
      setLoading(false);
      setButtonLoader(false);
      setProductCartData(null);
      console.log(error);
    }
  };

  const { notes, ...formDataObj } = formdata;

  const { model, ...modifiedData } = formDataObj;

  const { errors: formErrors } = useFormState({
    control: reactHookFormData.control,
  });
  const { model: modelError, ...restErors } = formErrors;

  const isDisabled =
    Object.keys(productCartDetail?.skipForOthers ? formErrors : restErors)
      .length > 0;

  return (
    <>
      {/* Render the component normally on larger screens */}
      {!isMobileOrTablet && (
        <div className="quote-cart">
          <div className="quote-width">
            <div className="quote-cart-header">Details</div>
            {/* Item */}
            <div className="quote-cart-item">
              <div className="quote-cart-item-section">
                {productCartDetail?.brand?.picture && (
                  <div className="quote-cart-item-img">
                    <img
                      className="w- h-7"
                      alt="P"
                      src={productCartDetail?.brand?.picture}
                    />
                  </div>
                )}
                <div className="quote-cart-item-title ">
                  <div className="quote-text3 rd-quote-truncate rd-quote-max-w-[180px]">
                    {productCartDetail?.brand?.name}
                  </div>
                  <div className="quote-text1 rd-quote-font-normal rd-quote-truncate rd-quote-max-w-[180px]">
                    {productCartDetail?.model?.name ||
                      productCartData?.device_name ||
                      formdata?.model}
                  </div>
                </div>
              </div>
            </div>
            {/* issues header */}
            {productCartDetail?.services && (
              <>
                <div className="quote-cart-issue-header">
                  <div className="quote-text1">
                    Issues({productCartDetail?.services?.length})
                  </div>
                </div>
                <div
                  className={`quote-cart-issue-scroll ${
                    progressState === 4 ? "!rd-quote-max-h-[160px]" : ""
                  }`}
                >
                  <IssueList />
                </div>
              </>
            )}
          </div>
          {/* bottom  */}
          <div className="rd-quote-flex rd-quote-flex-col rd-quote-justify-between">
            {currentState === "FORM" && (
              <div className="rd-quote-flex rd-quote-flex-row rd-quote-rounded-md rd-quote-border rd-quote-p-3 rd-quote-border-gray-200 rd-quote-justify-start rd-quote-items-start rd-quote-inline-flex">
                <span className="rd-quote-mr-2 rd-quote-bg-amber-100">
                  <IconPercentage className="rd-quote-w-5 rd-quote-h-5 rd-quote-text-amber-700" />
                </span>
                <p className="rd-quote-text-gray-700 rd-quote-text-sm rd-quote-font-normal rd-quote-font-['SF Pro Text'] rd-quote-leading-tight">
                  {userSettings?.promotionalText}
                </p>
              </div>
            )}
            {userSettings?.isPricesEnabled && (
              <div className="rd-quote-flex rd-quote-flex-row rd-quote-justify-between rd-quote-items-center rd-quote-bg-gray-100 rd-quote-rounded rd-quote-px-2 rd-quote-mt-2">
                <p className="rd-quote-text-gray-800 rd-quote-mt-3 !rd-quote-text-center rd-quote-text-base rd-quote-font-normal rd-quote-leading-Normal">
                  Sub Total
                </p>
                <p className="rd-quote-text-gray-800 rd-quote-mt-3 !rd-quote-text-center rd-quote-text-base rd-quote-font-medium  rd-quote-leading-normal">
                  {/* $ */}
                  {countryDetails?.currency_symbol}
                  {productCartDetail?.totalPrice === undefined
                    ? 0
                    : productCartDetail?.totalPrice?.toFixed(2)}
                </p>
              </div>
            )}

            {currentState === DISPLAYING_PAGES.PRODUCT && (
              <Button
                disabled={!productCartDetail?.services?.length > 0}
                text={"Continue"}
                setServiceArray={setServiceArray}
              />
            )}
            {(currentState === DISPLAYING_PAGES.FORM ||
              currentState === DISPLAYING_PAGES.BOOKING) &&
              userSettings?.isQuoteEnabled &&
              currentState === DISPLAYING_PAGES.BOOKING &&
              buttonClicked === "quote" &&
              !buttonLoader && (
                <Button
                  submit={() => submitForm("quote")}
                  text={"Get Quote"}
                  setWidth={true}
                  disabled={
                    !defaultStore ||
                    (!isKeyValueEmpty(modifiedData) &&
                      currentState === DISPLAYING_PAGES.FORM) ||
                    isDisabled
                  }
                />
              )}
            {(currentState === DISPLAYING_PAGES.FORM ||
              currentState === DISPLAYING_PAGES.BOOKING) &&
              ((userSettings?.isQuoteEnabled &&
                currentState === DISPLAYING_PAGES.BOOKING) ||
                (userSettings?.isAppointmentEnabled &&
                  currentState === DISPLAYING_PAGES.BOOKING)) &&
              buttonClicked === "appointment" &&
              !buttonLoader && (
                <Button
                  submit={() => submitForm("appointments")}
                  text={"Book an Appointment"}
                  setWidth={
                    currentState === DISPLAYING_PAGES.BOOKING ? false : true
                  }
                  disabled={
                    (!isKeyValueEmpty(modifiedData) &&
                      currentState === DISPLAYING_PAGES.FORM) ||
                    (!isKeyValueEmpty(appointmentDetail) &&
                      currentState === DISPLAYING_PAGES.BOOKING) ||
                    isDisabled
                  }
                />
              )}
              {(currentState === DISPLAYING_PAGES.FORM ||
                currentState === DISPLAYING_PAGES.BOOKING) &&
                userSettings?.isQuoteEnabled &&
                currentState === DISPLAYING_PAGES.BOOKING &&
                buttonLoader && (
                <Button
                  setWidth={true}
                  text={"Loading.."}
                  disabled
                />
              )}
            {currentState === DISPLAYING_PAGES.FORM &&
              userSettings?.isQuoteEnabled && (
                <Button
                  text={"Get Quote"}
                  setWidth={true}
                  submit={() => setButtonClicked("quote")}
                  disabled={!isKeyValueEmpty(modifiedData) || isDisabled}
                />
              )}
            {currentState === DISPLAYING_PAGES.FORM &&
              userSettings?.isAppointmentEnabled && (
                <Button
                  text={"Book an Appointment"}
                  setWidth={true}
                  typeA={true}
                  submit={() => setButtonClicked("appointment")}
                  disabled={!isKeyValueEmpty(modifiedData) || isDisabled}
                />
              )}
          </div>
        </div>
      )}

      {isMobileOrTablet && !isViewOpen && (
        <div className="quote-cart">
          <div className="quote-width">
            <div className="quote-cart-header quote-row-justify">
              <div>Details</div>
              {/* Show view button for mobile and tablet */}
              <button
                className="rd-quote-text-teal-600"
                onClick={() => toggleModal()}
              >
                {isViewOpen ? "Hide" : "View"}
              </button>
            </div>
          </div>

          <div className="rd-quote-flex rd-quote-flex-col rd-quote-justify-between">
            {currentState === "FORM" && (
              <div className="rd-quote-flex rd-quote-flex-row rd-quote-rounded-md rd-quote-border rd-quote-p-3 rd-quote-border-gray-200 rd-quote-justify-start rd-quote-items-start rd-quote-inline-flex">
                <span className="rd-quote-mr-2 rd-quote-bg-amber-100">
                  <IconPercentage className="rd-quote-w-5 rd-quote-h-5 rd-quote-text-amber-700" />
                </span>
                <p className="rd-quote-text-gray-700 rd-quote-text-sm rd-quote-font-normal rd-quote-font-['SF Pro Text'] rd-quote-leading-tight">
                  Get{" "}
                  <span className="rd-quote-text-teal-600 rd-quote-text-sm rd-quote-font-semibold rd-quote-font-['SF Pro Text'] rd-quote-leading-tight">
                    10%
                  </span>{" "}
                  discount when you book online
                </p>
              </div>
            )}
            {userSettings?.isPricesEnabled && (
              <div className="rd-quote-flex rd-quote-flex-row rd-quote-justify-between rd-quote-items-center rd-quote-bg-gray-100 rd-quote-rounded rd-quote-px-2 rd-quote-mt-2">
                <p className="rd-quote-text-gray-800 rd-quote-mt-3 !rd-quote-text-center rd-quote-text-base rd-quote-font-normal rd-quote-leading-Normal">
                  Sub Total
                </p>
                <p className="rd-quote-text-gray-800 rd-quote-mt-3 !rd-quote-text-center rd-quote-text-base rd-quote-font-medium  rd-quote-leading-normal">
                  {/* $ */}
                  {countryDetails?.currency_symbol}
                  {productCartDetail?.totalPrice === undefined
                    ? 0
                    : productCartDetail?.totalPrice?.toFixed(2)}
                </p>
              </div>
            )}
            {currentState === DISPLAYING_PAGES.PRODUCT && (
              <Button
                disabled={!productCartDetail?.services?.length > 0}
                text={"Continue"}
                setServiceArray={setServiceArray}
              />
            )}
            <div className="quote-row-justify">
              {(currentState === DISPLAYING_PAGES.FORM ||
                currentState === DISPLAYING_PAGES.BOOKING) &&
                userSettings?.isQuoteEnabled &&
                currentState === DISPLAYING_PAGES.BOOKING &&
                buttonClicked === "quote" &&
                !buttonLoader && (
                  <Button
                    submit={() => submitForm("quote")}
                    text={"Get Quote"}
                    setWidth={true}
                    disabled={
                      !defaultStore ||
                      (!isKeyValueEmpty(modifiedData) &&
                        currentState === DISPLAYING_PAGES.FORM) ||
                      isDisabled
                    }
                  />
                )}
              {(currentState === DISPLAYING_PAGES.FORM ||
                currentState === DISPLAYING_PAGES.BOOKING) &&
                ((userSettings?.isQuoteEnabled &&
                  currentState === DISPLAYING_PAGES.BOOKING) ||
                  (userSettings?.isAppointmentEnabled &&
                    currentState === DISPLAYING_PAGES.BOOKING)) &&
                buttonClicked === "appointment" && 
                !buttonLoader && (
                  <Button
                    submit={() => submitForm("appointments")}
                    text={"Book an Appointment"}
                    setWidth={
                      currentState === DISPLAYING_PAGES.BOOKING ? false : true
                    }
                    disabled={
                      (!isKeyValueEmpty(modifiedData) &&
                        currentState === DISPLAYING_PAGES.FORM) ||
                      (!isKeyValueEmpty(appointmentDetail) &&
                        currentState === DISPLAYING_PAGES.BOOKING) ||
                      isDisabled
                    }
                  />
                )}
              {(currentState === DISPLAYING_PAGES.FORM ||
                currentState === DISPLAYING_PAGES.BOOKING) &&
                userSettings?.isQuoteEnabled &&
                currentState === DISPLAYING_PAGES.BOOKING &&
                buttonLoader && (
                <Button
                  setWidth={true}
                  text={"Loading.."}
                  disabled
                />
              )}
              {currentState === DISPLAYING_PAGES.FORM &&
                userSettings?.isQuoteEnabled && (
                  <Button
                    text={"Get Quote"}
                    setWidth={true}
                    submit={() => setButtonClicked("quote")}
                    disabled={!isKeyValueEmpty(modifiedData) || isDisabled}
                  />
                )}
              {currentState === DISPLAYING_PAGES.FORM &&
                userSettings?.isAppointmentEnabled && (
                  <Button
                    text={"Book an Appointment"}
                    setWidth={true}
                    typeA={true}
                    submit={() => setButtonClicked("appointment")}
                    disabled={!isKeyValueEmpty(modifiedData) || isDisabled}
                  />
                )}
            </div>
          </div>
        </div>
      )}

      {isMobileOrTablet && isViewOpen && (
        <div className={`modal-container ${isViewOpen ? "show" : ""}`}>
          {/* <div className="modal"> */}
          {/* <div className="modal-content"> */}
          <div className="quote-cart-two">
            <div className="quote-width">
              <div className="quote-cart-header quote-row-justify">
                <div>Details</div>
                {/* Show view button for mobile and tablet */}
                <button
                  className="rd-quote-text-teal-600"
                  onClick={() => toggleModal()}
                >
                  {isViewOpen ? "Hide" : "View"}
                </button>
              </div>{" "}
              {/* Item */}
              <div className="quote-cart-item">
                <div className="quote-cart-item-section">
                  {productCartDetail?.model?.picture && (
                    <div className="quote-cart-item-img">
                      <img
                        className="w- h-7"
                        alt="P"
                        src={productCartDetail?.model?.picture}
                      />
                    </div>
                  )}
                  <div className="quote-cart-item-title ">
                    <div className="quote-text3 rd-quote-truncate rd-quote-max-w-[230px]">
                      {productCartDetail?.brand?.name}
                    </div>
                    <div className="quote-text1 rd-quote-font-normal">
                      {productCartDetail?.model?.name ||
                        productCartData?.device_name ||
                        formdata?.model}
                    </div>
                  </div>
                </div>
              </div>
              {/* issues header */}
              {productCartDetail?.services && (
                <>
                  <div className="quote-cart-issue-header">
                    <div className="quote-text1">
                      Issues({productCartDetail?.services?.length})
                    </div>
                  </div>
                  <div className="quote-cart-issue-scroll">
                    <IssueList />
                  </div>
                </>
              )}
            </div>
            {/* bottom  */}
            <div className="rd-quote-flex rd-quote-flex-col rd-quote-justify-between">
              {currentState === "FORM" && (
                <div className="rd-quote-flex rd-quote-flex-row rd-quote-rounded-md rd-quote-border rd-quote-p-3 rd-quote-border-gray-200 rd-quote-justify-start rd-quote-items-start rd-quote-inline-flex">
                  <span className="rd-quote-mr-2 rd-quote-bg-amber-100">
                    <IconPercentage className="rd-quote-w-5 rd-quote-h-5 rd-quote-text-amber-700" />
                  </span>
                  <p className="rd-quote-text-gray-700 rd-quote-text-sm rd-quote-font-normal rd-quote-font-['SF Pro Text'] rd-quote-leading-tight">
                    {userSettings?.promotionalText}
                  </p>
                </div>
              )}
              {userSettings?.isPricesEnabled && (
                <div className="rd-quote-flex rd-quote-flex-row rd-quote-justify-between rd-quote-items-center rd-quote-bg-gray-100 rd-quote-rounded rd-quote-px-2 rd-quote-mt-2">
                  <p className="rd-quote-text-gray-800 rd-quote-mt-3 !rd-quote-text-center rd-quote-text-base rd-quote-font-normal rd-quote-leading-Normal">
                    Sub Total
                  </p>
                  <p className="rd-quote-text-gray-800 rd-quote-mt-3 !rd-quote-text-center rd-quote-text-base rd-quote-font-medium  rd-quote-leading-normal">
                    {/* $ */}
                    {countryDetails?.currency_symbol}
                    {productCartDetail?.totalPrice === undefined
                      ? 0
                      : productCartDetail?.totalPrice?.toFixed(2)}
                  </p>
                </div>
              )}
              {currentState === DISPLAYING_PAGES.PRODUCT && (
                <Button
                  disabled={!productCartDetail?.services?.length > 0}
                  text={"Continue"}
                  setServiceArray={setServiceArray}
                />
              )}
              <div className="quote-row-justify">
                {(currentState === DISPLAYING_PAGES.FORM ||
                  currentState === DISPLAYING_PAGES.BOOKING) &&
                  userSettings?.isQuoteEnabled &&
                  currentState === DISPLAYING_PAGES.BOOKING &&
                  buttonClicked === "quote" &&
                  !buttonLoader && (
                    <Button
                      submit={() => submitForm("quote")}
                      text={"Get Quote"}
                      setWidth={true}
                      disabled={
                        !defaultStore ||
                        (!isKeyValueEmpty(modifiedData) &&
                          currentState === DISPLAYING_PAGES.FORM) ||
                        isDisabled
                      }
                    />
                  )}
                {(currentState === DISPLAYING_PAGES.FORM ||
                  currentState === DISPLAYING_PAGES.BOOKING) &&
                  ((userSettings?.isQuoteEnabled &&
                    currentState === DISPLAYING_PAGES.BOOKING) ||
                    (userSettings?.isAppointmentEnabled &&
                      currentState === DISPLAYING_PAGES.BOOKING)) &&
                  buttonClicked === "appointment" &&
                  !buttonLoader && (
                    <Button
                      submit={() => submitForm("appointments")}
                      text={"Book an Appointment"}
                      setWidth={
                        currentState === DISPLAYING_PAGES.BOOKING ? false : true
                      }
                      disabled={
                        (!isKeyValueEmpty(modifiedData) &&
                          currentState === DISPLAYING_PAGES.FORM) ||
                        (!isKeyValueEmpty(appointmentDetail) &&
                          currentState === DISPLAYING_PAGES.BOOKING) ||
                        isDisabled
                      }
                    />
                  )}
                {(currentState === DISPLAYING_PAGES.FORM ||
                  currentState === DISPLAYING_PAGES.BOOKING) &&
                  userSettings?.isQuoteEnabled &&
                  currentState === DISPLAYING_PAGES.BOOKING &&
                  buttonLoader && (
                  <Button
                    setWidth={true}
                    text={"Loading.."}
                    disabled
                  />
                )}
                {currentState === DISPLAYING_PAGES.FORM &&
                  userSettings?.isQuoteEnabled && (
                    <Button
                      text={"Get Quote"}
                      setWidth={true}
                      submit={() => setButtonClicked("quote")}
                      disabled={!isKeyValueEmpty(modifiedData) || isDisabled}
                    />
                  )}
                {currentState === DISPLAYING_PAGES.FORM &&
                  userSettings?.isAppointmentEnabled && (
                    <Button
                      text={"Book an Appointment"}
                      setWidth={true}
                      typeA={true}
                      submit={() => setButtonClicked("appointment")}
                      disabled={!isKeyValueEmpty(modifiedData) || isDisabled}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
