import './App.css';
import AppManager from './AppManager';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
     <AppManager/>
    </div>
  );
}

export default App;
