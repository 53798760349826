import AppContext from "Context/appContext";
import axios from "axios";
import "components/Card/index.css";
import { useContext } from "react";

const Card = (props) => {
  const api_base_url = process.env.REACT_APP_APPOINTMENT_PRO_API_URL;
  const { index, product, filteredProducts, Icon, setSearch, setProducts } =
    props;
  const {
    currentState,
    setLoading,
    setProductCartData,
    progressState,
    productCartDetail,
    encryptionKey,
    setFormData,
    formdata,
    notificationWay,
    setNotificationWay
  } = useContext(AppContext);

  const getDataFromRD = async () => {
    setLoading(true);
    try {
      const { data, status } = await axios.post(
        `${api_base_url}/settings/rd-device-details`,
        {
          bzy_d: product?.rd_device_id,
          q_: encryptionKey,
        }
      );
      if (status === 200) {
        const item = data.data[0];
        const price = item?.sale_price?.startsWith("0")
          ? item?.retail_price
          : item?.sale_price;
        // setProducts(data?.data?.length > 0 ? data : allServicesForSubId);
        setProducts(data?.data);

        setProductCartData({
          name: item.name,
          device_name: item.device_name,
          rd_device_id: product.rd_device_id,
          price: price,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getModelServices = async () => {
    setLoading(true);
    try {
      const { data, status } = await axios.get(
        `${api_base_url}/service/model/${product._id}?q_=${encryptionKey}`
      );
      if (status === 200) {
        // setProducts(data.length > 0 ? data : allServicesForSubId);
        setProducts(data);
        setFormData({ ...formdata, model: product.name });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updateValue = () => {
    currentState === "PRODUCT" && progressState !== 3 && setSearch("");
    if (index !== undefined) filteredProducts(index, product?.name);

    if (progressState === 4) {
      setNotificationWay(product?.value);
    }

    if (productCartDetail?.skipForOthers) {
      // others case flow
      filteredProducts(index, product?.name);
      if (progressState === 3) {
        setProductCartData(null);
        setFormData({ ...formdata, model: "" });
      }
    } else if (product.brand && product.isRD) {
      // repairdesk database case flow
      getDataFromRD();
    } else if (product.brand && !product?.isRD && !product.model) {
      // our database case flow
      getModelServices();
    }
  };

  return (
    (product.name !== "Others" || progressState !== 3) && (
      <button
        onClick={updateValue}
        className={`quote-product-card quote-product-card-width hover:rd-quote-border-2 hover:rd-quote-border-teal-600 ${
          product.active
            ? "rd-quote-border-2 rd-quote-rounded-md rd-quote-border-teal-600"
            : ""
        } ${
          product?.model?.name
            ? "rd-quote-rounded-md"
            : product?.value === "sms" ||
              product?.value === "email" ||
              product?.value === "phone"
            ? "rd-quote-h-[112px] rd-quote-rounded-md"
            : progressState === 3
            ? "rd-quote-h-[160px] rd-quote-w-[245px] rd-quote-rounded-md"
            : "quote-card-width-mobile rd-quote-h-[214px] rd-quote-w-[304px] -rd-quote-mb-[10px]"
        } ${
          ((product?.value === notificationWay) && progressState === 4)
            ? 'rd-quote-border-2 rd-quote-border-teal-600'
            : ''} `}
        type="submit"
      >
        {!Icon && product.picture && (
          <img
            className={`quote-product-card-img ${
              product?.model?.name || product?.name ? "" : "rd-quote-h-[140px]"
            }`}
            alt="P"
            src={product.picture}
          />
        )}
        <span className="rd-quote-text-gray-500"> {Icon} </span>
        <p
          className={`quote-text3 rd-quote-max-w-[130px] rd-quote-text-center ${
            !product.picture && progressState !== 4
              ? "rd-quote-font-bold rd-quote-text-base"
              : ""
          } `}
        >
          {product?.name}
        </p>
      </button>
    )
  );
};

export default Card;
