import axios from 'axios';

export const fetchSettingsForSubId = async (url) => {
    const response = await axios(url);
    return response.data;
}


export const fetchAllServicesForSubId = async (url) => {
    const response = await axios(url);
    return response.data;
}